import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Spinner from '../Components/Spinner';
import { submitNewPassword } from '../PtoAuth';
import Nav from '../Components/Nav';
import { getLoginUrl, getRegisterUrl } from '../Urls';
import Main from '../Components/Main';
import { getPasswordErrorMessage } from '../Utils';

class SubmitNewPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.emailAddress,
      code: '',
      newPassword: '',
      canSubmitPassword: false,
      isSubmittingPassword: false,
      submitPasswordError: null,
      redirectToLogin: false
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isSubmittingPassword: true });

    try {
      await submitNewPassword(this.state.username, this.state.code, this.state.newPassword);
      this.setState({ isSubmittingPassword: false, redirectToLogin: true })
    }
    catch (err) {
      this.setState({
        isSubmittingPassword: false,
        submitPasswordError: getPasswordErrorMessage(err)
      });
    }
  };

  setCanSubmitPassword(username, code, newPassword) {
    this.setState({ canSubmitPassword: username && code && newPassword });
  }

  onUsernameChange = (e) => {
    const username = e.target.value;
    this.setState({ username: username });

    this.setCanSubmitPassword(username, this.state.code, this.state.newPassword);
  }

  onCodeChange = (e) => {
    const code = e.target.value;
    this.setState({ code: code });

    this.setCanSubmitPassword(this.state.username, code, this.state.newPassword);
  }

  onNewPasswordChange = (e) => {
    const newPassword = e.target.value;
    this.setState({ newPassword: newPassword });

    this.setCanSubmitPassword(this.state.username, this.state.code, newPassword);
  }

  render() {
    if (this.state.redirectToLogin) {
      return (<Redirect to={getLoginUrl(this.state.username, false)} />);
    }

    return (
      <>
        <Nav onLoggedOut={async () => await this.props.onLoggedOut()} />
        <Main>
          <h2>Submit New Password</h2>

          <p>Check your email for a code which will enable you to reset your password.</p>

          <div className="row">
            <div className="col-md-8 col-lg-6 col-xl-4">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Email address</label>
                  <input type="text" className="form-control" name="username" onChange={(e) => this.onUsernameChange(e)} value={this.state.username} />
                </div>
                <div className="form-group">
                  <label>Code (from email)</label>
                  <input type="text" className="form-control" name="code" onChange={(e) => this.onCodeChange(e)} value={this.state.code} />
                </div>
                <div className="form-group">
                  <label>New password</label>
                  <input type="password" className="form-control" name="newPassword" onChange={(e) => this.onNewPasswordChange(e)} value={this.state.newPassword} />
                </div>
                <div className="form-group">
                  <input type="submit" className="btn btn-primary mr-3" value="Submit new password" disabled={!this.state.canSubmitPassword} />
                  <Spinner showSpinner={this.state.isSubmittingPassword} />
                </div>
                <div className="form-group text-danger">
                  {this.state.submitPasswordError}
                </div>
              </form>
            </div>
          </div>

          <h4>More ...</h4>

          <ul>
            <li>
              <Link to={getLoginUrl(this.state.username, false)}>I want to log in</Link>
            </li>
            <li>
              <Link to={getRegisterUrl(this.state.username)}>I want to create a new account</Link>
            </li>
          </ul>
        </Main>
      </>
    );
  }
}

export default SubmitNewPasswordPage;