import React from 'react';
import { localStorageSet, localStorageGet } from '../PtoLocalStorage';

export const showPlayersOptions = {
  ALL: "ALL",
  ONLY_HUMANS: "ONLY_HUMANS",
  ONLY_BOTS: "ONLY_BOTS"
}

function getOptionName(showPlayersOption) {
  switch (showPlayersOption) {
    case showPlayersOptions.ALL:
      return "Show all players";
    case showPlayersOptions.ONLY_HUMANS:
      return "Show only humans";
    case showPlayersOptions.ONLY_BOTS:
      return "Show only bots";
    default:
      return null;
  }
}

function Option(props) {
  const active = props.option === props.selectedOption;

  return (
    <button
      className={`dropdown-item ${active ? "active" : null}`}
      type="button"
      onClick={() => props.onSelect(props.option)}>
      {getOptionName(props.option)}
    </button>
  );
}

export default class ShowPlayersOption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPlayersOption: localStorageGet("showPlayersOption") ?? showPlayersOptions.ONLY_HUMANS
    }

    this.props.onSetValue(this.state.showPlayersOption);
  }

  selectOption(showPlayersOption) {
    this.setState({ showPlayersOption: showPlayersOption });
    this.props.onSetValue(showPlayersOption);
    localStorageSet("showPlayersOption", showPlayersOption);
  }

  render() {
    return (
      <div className="form-group mb-2">
        <div className="dropdown">
          <button className="btn btn-outline-primary btn-block dropdown-toggle" type="button" id="showPlayersMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {getOptionName(this.state.showPlayersOption)}
          </button>
          <div className="dropdown-menu" aria-labelledby="showPlayersMenuButton">
            <Option option={showPlayersOptions.ALL} selectedOption={this.state.showPlayersOption} onSelect={v => this.selectOption(v)} />
            <Option option={showPlayersOptions.ONLY_HUMANS} selectedOption={this.state.showPlayersOption} onSelect={v => this.selectOption(v)} />
            <Option option={showPlayersOptions.ONLY_BOTS} selectedOption={this.state.showPlayersOption} onSelect={v => this.selectOption(v)} />
          </div>
        </div>
      </div>
    );
  }
}