import React from 'react';
import { BrowserRouter } from "react-router-dom";
import PageBody from './PageBody';
import Footer from './Footer';
import { getCurrentSessionOrNull } from './PtoAuth';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoggedIn: false
    };
  }

  async getAndStoreSession() {
    this.setState({ isLoading: true });
    let currentSessionOrNull = await getCurrentSessionOrNull();

    if (currentSessionOrNull === null) {
      this.setState({ isLoading: false, isLoggedIn: false });
      return;
    }

    this.setState({ isLoading: false, isLoggedIn: true });
  }

  async onLoggedIn() {
    await this.getAndStoreSession();
  }

  async onLoggedOut() {
    await this.getAndStoreSession();
  }

  async componentDidMount() {
    await this.getAndStoreSession();
  }

  render() {
    return (
      <BrowserRouter>
        {process.env.REACT_APP_TEST_SYSTEM ? <div className="bg-danger text-center">TEST SYSTEM</div> : null}
        <PageBody
          isLoading={this.state.isLoading}
          isLoggedIn={this.state.isLoggedIn}
          onLoggedOut={async () => await this.onLoggedOut()}
          onLoggedIn={async () => await this.onLoggedIn()} />
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;
