import React from 'react';
import TeamImage from '../Components/TeamImage';

export default function TeamDisplay(props) {
  return (
    <div className="text-center">
      <div>
        <TeamImage team={props.team} height="30" />
      </div>
      <div>
        {props.teamName}
      </div>
    </div>
  );
};