import React from 'react';

import Name from '../Components/Name';
import { Dropdown, DropdownItem, DropdownDivider } from '../Components/Dropdown';

const RoundName = (props) => {
  return (
    <Name name={props.round.name} shortName={props.round.shortName} breakpoint="sm" />
  );
};

export default function RoundsDropdown(props) {
  const overallName = "Overall";

  return (
    <Dropdown title={props.round ? <RoundName round={props.round} /> : overallName}>
      {
        props.includeOverall ?
          <DropdownItem active={!props.round} linkDestination={props.getOverallLinkDestination()}>{overallName}</DropdownItem> :
          null
      }
      {
        props.includeOverall ? <DropdownDivider /> : null
      }
      {
        props.rounds
          .map(r =>
            <DropdownItem key={r.code} active={r.code === props.round?.code} linkDestination={props.getRoundLinkDestination(r.code)}>
              <RoundName round={r} />
            </DropdownItem>)
      }
    </Dropdown>
  );
};