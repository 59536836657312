import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import Leaderboard from '../Components/Leaderboard';
import ShowPlayersOption, { showPlayersOptions } from '../Components/ShowPlayersOption';
import TournamentsDropdown from '../Components/TournamentsDropdown';
import RoundsDropdown from '../Components/RoundsDropdown';
import Nav, { navPages } from '../Components/Nav';

import { getRoundLeaderboardPage, getTournamentLeaderboardPage } from '../PtoApi';
import { getPredictedTableUrl, getRoundLeaderboardUrl, getRoundPredictionsUrl, getTournamentLeaderboardUrl } from '../Urls';
import Main from '../Components/Main';

export default function LeaderboardPage(props) {
  const [tournaments, setTournaments] = useState(null);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [rounds, setRounds] = useState(null);
  const [selectedRound, setSelectedRound] = useState(null);
  const [leaderboard, setLeaderboard] = useState(null);
  const [humansLeaderboard, setHumansLeaderboard] = useState(null);
  const [botsLeaderboard, setBotsLeaderboard] = useState(null);
  const [showPlayersOption, setShowPlayersOption] = useState("ALL");

  useEffect(
    () => {
      const fetchData = async () => {
        // Clear previous values
        setTournaments(null);
        setSelectedTournament(null);
        setRounds(null);
        setSelectedRound(null);
        setLeaderboard(null);
        setHumansLeaderboard(null);
        setBotsLeaderboard(null);

        let response;
        if (props.roundCode) {
          response = await getRoundLeaderboardPage(props.roundCode);
        } else {
          response = await getTournamentLeaderboardPage(props.tournamentCode);
        }

        const json = await response.json();

        setTournaments(json.tournaments);
        setSelectedTournament(json.tournament);
        setRounds(json.rounds);
        setSelectedRound(json.round);
        setLeaderboard(json.leaderboard);
        setHumansLeaderboard(json.humansLeaderboard);
        setBotsLeaderboard(json.botsLeaderboard);
      };

      fetchData();
    },
    [props.roundCode, props.tournamentCode]);

  const getPlayerLinkDestination = (playerId) => {
    if (props.roundCode) {
      return getRoundPredictionsUrl(props.roundCode, playerId);
    } else if (selectedTournament.isLeague) {
      return getPredictedTableUrl(props.tournamentCode, playerId);
    }
  };

  return (
    <>
      <Nav currentPage={navPages.LEADERBOARD} onLoggedOut={async () => await props.onLoggedOut()} />
      <Main>
        <div className="row">
          <div className="col mb-3">
            {
              tournaments && selectedTournament ?
                <TournamentsDropdown
                  tournaments={tournaments}
                  tournament={selectedTournament}
                  getLinkDestination={t => getTournamentLeaderboardUrl(t.code)} /> :
                <Skeleton />
            }
          </div>
          <div className="col mb-3">
            {
              rounds && selectedTournament ?
                <RoundsDropdown
                  rounds={rounds}
                  round={selectedRound}
                  includeOverall={true}
                  getOverallLinkDestination={() => getTournamentLeaderboardUrl(selectedTournament.code)}
                  getRoundLinkDestination={code => getRoundLeaderboardUrl(code)} /> :
                <Skeleton />
            }
          </div>
          <div className="col mb-3">
            {
              selectedTournament && selectedTournament.botsAllowed ?
                <ShowPlayersOption onSetValue={v => setShowPlayersOption(v)} /> :
                null
            }
          </div>
        </div>
        <div>
          {
            leaderboard ?
              <Leaderboard
                leaderboard={leaderboard}
                humansLeaderboard={humansLeaderboard}
                botsLeaderboard={botsLeaderboard}
                getPlayerLinkDestination={getPlayerLinkDestination}
                showPlayersOption={
                  selectedTournament && selectedTournament.botsAllowed ?
                    showPlayersOption :
                    showPlayersOptions.ONLY_HUMANS
                } /> :
              <Skeleton count={5} />
          }
        </div>
      </Main>
    </>
  );
};