import { Auth } from 'aws-amplify';

export async function getCurrentSessionOrNull() {
  try {
    return await Auth.currentSession();
  } catch (err) {
    return null;
  }
}

export async function signIn(username, password) {
  await Auth.signIn(username, password);
}

export async function signUp(username, password) {
  await Auth.signUp(username, password);
}

export async function resendSignUp(username) {
  await Auth.resendSignUp(username);
};

export async function confirmUser(username, confirmationCode) {
  await Auth.confirmSignUp(username, confirmationCode);
}

export async function changePassword(oldPassword, newPassword) {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(user, oldPassword, newPassword);
}

export async function resetPassword(username) {
  await Auth.forgotPassword(username);
}

export async function submitNewPassword(username, code, newPassword) {
  await Auth.forgotPasswordSubmit(username, code, newPassword);
}

export async function signOut() {
  await Auth.signOut();
}