import React from 'react';
import { signIn } from '../PtoAuth';
import { Link } from 'react-router-dom';
import Spinner from '../Components/Spinner';
import Nav from '../Components/Nav';
import { getConfirmEmailAddressUrl, getForgotPasswordUrl, getRegisterUrl } from '../Urls';
import Main from '../Components/Main';
import SupportLink from '../Components/SupportLink';

const PleaseLogInAlert = (props) => {
  return (
    <div className="alert alert-success" role="alert">
      Your email address has been confirmed! For your security, please log in.
    </div>
  );
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.emailAddress,
      password: '',
      isLoggingIn: false,
      loginError: null,
      canLogin: false
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isLoggingIn: true });

    try {
      await signIn(this.state.username, this.state.password);
      await this.props.onLoggedIn();
    }
    catch (err) {
      this.setState({
        isLoggingIn: false,
        loginError: err.message
      });
    }
  };

  setCanLogin(username, password) {
    this.setState({ canLogin: username && password });
  }

  onUsernameChange = (e) => {
    const username = e.target.value;
    this.setState({ username: username });

    this.setCanLogin(username, this.state.password);
  }

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState({ password: password });

    this.setCanLogin(this.state.username, password);
  }

  render() {
    return (
      <>
        <Nav onLoggedOut={async () => await this.props.onLoggedOut()} />
        <Main>
          {
            this.props.emailJustConfirmed ?
              <PleaseLogInAlert /> :
              null
          }
          <h2>Log In</h2>

          <div className="row">
            <div className="col-md-8 col-lg-6 col-xl-4">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Email address</label>
                  <input type="text" className="form-control" name="username" defaultValue={this.state.username} onChange={(e) => this.onUsernameChange(e)} value={this.state.username} />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input type="password" className="form-control" name="password" onChange={(e) => this.onPasswordChange(e)} value={this.state.password} />
                </div>
                <div className="form-group">
                  <input type="submit" className="btn btn-primary mr-3" value="Log In" disabled={!this.state.canLogin} />
                  <Spinner showSpinner={this.state.isLoggingIn} />
                </div>
                <div className="form-group text-danger">
                  {this.state.loginError}
                </div>
              </form>
            </div>
          </div>

          <h4>More ...</h4>

          <ul>
            <li>
              <Link to={getRegisterUrl(this.state.username)}>I want to create a new account</Link>
            </li>
            <li>
              <Link to={getConfirmEmailAddressUrl(this.state.username)}>I've been sent a code to confirm my email address</Link>
            </li>
            <li>
              <Link to={getForgotPasswordUrl(this.state.username)}>I forgot my password!</Link>
            </li>
            <li>
              Having trouble? Email <SupportLink /> with any questions.
            </li>
          </ul>
        </Main>
      </>
    );
  }
}

export default LoginPage;