import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

function SavePlaceholder(props) {
  if (props.status === 'SAVING') {
    return (
      <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true" />
    );
  }
  else if (props.status === 'SAVED') {
    return (
      <FontAwesomeIcon icon={faCheck} className="text-success" />
    );
  }
  else if (props.status === 'FAILED') {
    return (
      <>
        <FontAwesomeIcon icon={faTimes} className="text-danger mr-3" />
        <span>{props.error}</span>
      </>
    );
  }
  else {
    return null;
  }
}

export default SavePlaceholder;