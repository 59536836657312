import React from 'react';
import { Link } from 'react-router-dom';
import linq from 'linq';
import Player from './Player';
import { showPlayersOptions } from './ShowPlayersOption';

function LeaderboardHeaderRow() {
  return (
    <tr>
      <th>#</th>
      <th>Player</th>
      <th><abbr title="Matches predicted">MP</abbr></th>
      <th><abbr title="Points">PTS</abbr></th>
      <th><abbr title="Average points per match">AVG</abbr></th>
    </tr>
  );
}

function LeaderboardRow(props) {
  const trClassName = props.leaderboardItem.player.isMe ? "table-info" : null;

  const linkDestination = props.getPlayerLinkDestination(props.leaderboardItem.player.id);

  const playerCellContents = linkDestination ?
    <Link to={linkDestination}>
      <Player player={props.leaderboardItem.player} />
    </Link> :
    <Player player={props.leaderboardItem.player} />;


  return (
    <tr className={trClassName}>
      <td>{props.leaderboardItem.rank}{props.leaderboardItem.rankIsShared ? "=" : null}</td>
      <td>
        {playerCellContents}
      </td>
      <td>{props.leaderboardItem.matchesPredicted}</td>
      <td>{props.leaderboardItem.totalPoints}</td>
      <td>{props.leaderboardItem.pointsPerMatch.toFixed(2)}</td>
    </tr>
  );
}

export default class Leaderboard extends React.Component {
  getLeaderboardToUse() {
    switch (this.props.showPlayersOption) {
      case showPlayersOptions.ALL:
        return this.props.leaderboard;
      case showPlayersOptions.ONLY_HUMANS:
        return this.props.humansLeaderboard;
      case showPlayersOptions.ONLY_BOTS:
        return this.props.botsLeaderboard;
      default:
        return null;
    }
  }

  render() {
    const leaderboardToUse = this.getLeaderboardToUse();

    const rows = linq.from(leaderboardToUse)
      .select(li =>
        <LeaderboardRow
          key={li.player.id}
          leaderboardItem={li}
          getPlayerLinkDestination={this.props.getPlayerLinkDestination} />)
      .toArray();

    return (
      <>
        <table className="table table-sm table-striped table-hover">
          <tbody>
            <LeaderboardHeaderRow />
            {rows}
          </tbody>
        </table>
      </>
    );
  }
}