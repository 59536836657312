import React from 'react';
import { confirmUser, resendSignUp } from '../PtoAuth';
import { Redirect } from 'react-router-dom';
import Nav from '../Components/Nav';
import Main from '../Components/Main';
import SupportLink from '../Components/SupportLink';
import { getLoginUrl } from '../Urls';
import SavePlaceholder from '../SavePlaceholder';
import Alert, { alertLevels } from '../Components/Alert';

class ConfirmEmailAddressPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.emailAddress,
      confirmationCode: '',
      saveStatus: null,
      redirectToLogin: false,
      error: null,
      // TODO: extract functions for "can" properties
      canConfirm: props.emailAddress && "",
      canResend: Boolean(props.emailAddress)
    };
  }

  confirmEmailAddress = async () => {
    this.setState({ saveStatus: "SAVING" });

    try {
      await confirmUser(this.state.username, this.state.confirmationCode);
      this.setState({ saveStatus: "SAVED", redirectToLogin: true })
    } catch (err) {
      this.setState({
        saveStatus: "FAILED",
        error: err.message
      });
    }
  };

  resendConfirmationCode = async () => {
    this.setState({ saveStatus: "SAVING" });

    try {
      await resendSignUp(this.state.username);
      this.setState({ saveStatus: "SAVED" })
    } catch (err) {
      this.setState({
        saveStatus: "FAILED",
        error: err.message
      });
    }
  };

  setCanConfirm(username, confirmationCode) {
    this.setState({ canConfirm: username && confirmationCode });
  }

  setCanResend(username) {
    this.setState({ canResend: Boolean(username) });
  }

  onUsernameChange = (e) => {
    const username = e.target.value;
    this.setState({ username: username });

    this.setCanConfirm(username, this.state.confirmationCode);
    this.setCanResend(username);
  }

  onConfirmationCodeChange = (e) => {
    const confirmationCode = e.target.value;
    this.setState({ confirmationCode: confirmationCode });

    this.setCanConfirm(this.state.username, confirmationCode);
  }

  render() {
    if (this.state.redirectToLogin) {
      return (<Redirect to={getLoginUrl(this.state.username, true)} />);
    }

    return (
      <>
        <Nav onLoggedOut={async () => await this.props.onLoggedOut()} />
        <Main>
          <h2>Confirm email address</h2>
          <div className="row">
            <div className="col-md-8 col-lg-6">
              <p>Please check your email and enter the confirmation code below.</p>
              <Alert level={alertLevels.LIGHT}>If you can't find the email, try looking in the junk folder.</Alert>
              <div className="form-group">
                <label>Email address</label>
                <input type="text" className="form-control" name="username" defaultValue={this.state.username} onChange={(e) => this.onUsernameChange(e)} />
              </div>
              <div className="form-group">
                <label>Confirmation code</label>
                <input type="text" className="form-control" name="confirmationCode" onChange={(e) => this.onConfirmationCodeChange(e)} />
              </div>
              <div className="form-group">
                <button className="btn btn-primary mr-3 mb-3" disabled={!this.state.canConfirm} onClick={this.confirmEmailAddress}>Confirm</button>
                <button className="btn btn-outline-primary mr-3 mb-3" disabled={!this.state.canResend} onClick={this.resendConfirmationCode}>Resend confirmation code</button>
                <div>
                  <SavePlaceholder status={this.state.saveStatus} error={this.state.error} />
                </div>
              </div>
            </div>
          </div>
          <p>Having trouble? Email <SupportLink /> with any questions.</p>
        </Main>
      </>
    );
  }
}

export default ConfirmEmailAddressPage;