import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const getAlertClassName = (level) => {
  switch (level) {
    case alertLevels.LIGHT:
      return "alert-light";
    case alertLevels.INFORMATION:
      return "alert-info";
    default:
      return null;
  }
};

const getIconClassName = (level) => {
  switch (level) {
    case alertLevels.LIGHT:
      return "text-secondary";
    case alertLevels.INFORMATION:
      return null;
    default:
      return null;
  }
};

export default function Alert(props) {
  return (
    <div className={`alert ${getAlertClassName(props.level)} d-flex flex-row align-items-center`} role="alert">
      <FontAwesomeIcon icon={faInfoCircle} className={`${getIconClassName(props.level)} mr-3`} />
      <span>
        {props.children}
      </span>
    </div>
  );
};

export const alertLevels = {
  LIGHT: "light",
  INFORMATION: "information"
}