import React from 'react';
import { resetPassword } from "../PtoAuth";
import { Link, Redirect } from 'react-router-dom';
import Spinner from '../Components/Spinner';
import Nav from '../Components/Nav';
import { getLoginUrl, getRegisterUrl, getSubmitNewPasswordUrl } from '../Urls';
import Main from '../Components/Main';
import SupportLink from '../Components/SupportLink';

class ForgotPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.emailAddress,
      canResetPassword: Boolean(props.emailAddress),
      isResettingPassword: false,
      resetPasswordError: null,
      redirectToSubmit: false
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isResettingPassword: true });

    try {
      await resetPassword(this.state.username);
      this.setState({ isResettingPassword: false, redirectToSubmit: true })
    }
    catch (err) {
      this.setState({
        isResettingPassword: false,
        resetPasswordError: err.message
      });
    }
  };

  setCanResetPassword(username) {
    this.setState({ canResetPassword: username });
  }

  onUsernameChange = (e) => {
    const username = e.target.value;
    this.setState({ username: username });

    this.setCanResetPassword(username);
  }

  render() {
    if (this.state.redirectToSubmit) {
      return (<Redirect to={getSubmitNewPasswordUrl(this.state.username)} />);
    }

    return (
      <>
        <Nav onLoggedOut={async () => await this.props.onLoggedOut()} />
        <Main>
          <h2>Forgot Password</h2>

          <div className="row">
            <div className="col-md-8 col-lg-6 col-xl-4">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Email address</label>
                  <input type="text" className="form-control" name="username" defaultValue={this.state.username} onChange={(e) => this.onUsernameChange(e)} value={this.state.username} />
                </div>
                <div className="form-group">
                  <input type="submit" className="btn btn-primary mr-3" value="Send password reset email" disabled={!this.state.canResetPassword} />
                  <Spinner showSpinner={this.state.isResettingPassword} />
                </div>
                <div className="form-group text-danger">
                  {this.state.resetPasswordError}
                </div>
              </form>
            </div>
          </div>

          <h4>More ...</h4>

          <ul>
            <li>
              <Link to={getLoginUrl(this.state.username, false)}>I want to log in</Link>
            </li>
            <li>
              <Link to={getRegisterUrl(this.state.username)}>I want to create a new account</Link>
            </li>
            <li>
              Having trouble? Email <SupportLink /> with any questions.
            </li>
          </ul>
        </Main>
      </>
    );
  }
}

export default ForgotPasswordPage;