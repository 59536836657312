import React from 'react';
import Score from '../Components/Score';
import Points from '../Components/Points';
import linq from 'linq';
import { getBreakdownPage } from '../PtoApi';
import { getPredictionResultClass } from '../Utils';
import { Link } from 'react-router-dom';
import Player from '../Components/Player';
import ShowPlayersOption, { showPlayersOptions } from '../Components/ShowPlayersOption';

import TournamentsDropdown from '../Components/TournamentsDropdown';
import RoundsDropdown from '../Components/RoundsDropdown';
import Nav, { navPages } from '../Components/Nav';
import Main from '../Components/Main';

import { getBreakdownUrl, getMatchUrl, getRoundPredictionsUrl } from '../Urls';
import Skeleton from 'react-loading-skeleton';

function HeaderCell(props) {
  const scoreOrVersus = props.match.finalScore ? <Score score={props.match.finalScore} /> : "v";

  return (
    <th className="text-center">
      <div>{props.match.homeTeam.shortName} </div>
      <div>
        <Link to={getMatchUrl(props.match.id)}>
          {scoreOrVersus}
        </Link>
      </div>
      <div>{props.match.awayTeam.shortName}</div>
    </th>
  );
}

function PlayerRow(props) {
  const trClassName = props.breakdownPlayer.player.isMe ? "table-info" : null;

  const predictionCells = linq.from(props.orderedMatches)
    .select(m => <PredictionCell key={m.id} match={m} player={props.breakdownPlayer.player} predictions={props.predictions} />)
    .toArray();

  return (
    <tr className={trClassName}>
      <th>
        <div>
          <Link to={getRoundPredictionsUrl(props.round.code, props.breakdownPlayer.player.id)}>
            <Player player={props.breakdownPlayer.player} />
          </Link>
        </div>
        <div>
          <Points points={props.breakdownPlayer.totalPoints} />
        </div>
      </th>
      {predictionCells}
    </tr>
  );
}

function PredictionCell(props) {
  const predictionOrNull = linq.from(props.predictions)
    .singleOrDefault(p => p.playerId === props.player.id && p.matchId === props.match.id);

  if (!predictionOrNull) {
    return <td>&nbsp;</td>
  }

  const prediction = predictionOrNull;

  const score = prediction.predictedScore ? <Score score={prediction.predictedScore} /> : "?";
  const points = <Points points={prediction.points} />;

  const className = getPredictionResultClass(prediction.predictionResult);

  return (
    <td className="text-center">
      <div className="font-weight-bold">{score}</div>
      <div className={className}>{points}</div>
    </td>
  );
}

const BreakdownTable = (props) => {
  const shouldShow = (player) => {
    switch (props.showPlayersOption) {
      case showPlayersOptions.ALL:
        return true;
      case showPlayersOptions.ONLY_HUMANS:
        return player.isHuman;
      case showPlayersOptions.ONLY_BOTS:
        return !player.isHuman;
      default:
        return null;
    }
  };

  const orderedMatches = linq.from(props.breakdownPage.matches)
    .orderBy(m => m.kickoff)
    .thenBy(m => m.homeTeam.name)
    .toArray();

  const orderedBreakdownPlayers = linq.from(props.breakdownPage.players)
    .where(bp => shouldShow(bp.player))
    .orderByDescending(bp => bp.totalPoints)
    .thenBy(bp => bp.player.name)
    .toArray();

  const headerCells = linq.from(orderedMatches)
    .select(m => <HeaderCell key={m.id} match={m} />)
    .toArray();

  const playerRows = linq.from(orderedBreakdownPlayers)
    .select(bp =>
      <PlayerRow
        key={bp.player.id}
        breakdownPlayer={bp}
        orderedMatches={orderedMatches}
        predictions={props.breakdownPage.predictions}
        round={props.breakdownPage.round} />)
    .toArray();

  return (
    <div className="mt-3 table-scrollable" >
      <table className="table table-striped table-sm table-hover">
        <thead>
          <tr>
            <th>&nbsp;</th>
            {headerCells}
          </tr>
        </thead>
        <tbody>
          {playerRows}
        </tbody>
      </table>
    </div >
  );
};

export default class BreakdownPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      breakdownPage: null,
      showPlayersOption: "ALL"
    };
  }

  load = async () => {
    try {
      this.setState({ isLoading: true, breakdownPage: null });

      let response = await getBreakdownPage(this.props.roundCode);

      let json = await response.json();

      this.setState({
        breakdownPage: json
      });
    }
    catch (err) {
      this.setState({ result: 'Error!' });
    }
    finally {
      this.setState({ isLoading: false });
    }
  }

  async componentDidMount() {
    await this.load();
  }

  async componentWillReceiveProps(newProps) {
    this.props = newProps;
    await this.load();
  }

  onShowPlayersOptionChange(showPlayersOption) {
    this.setState({ showPlayersOption: showPlayersOption });
  }

  render() {
    return (
      <>
        <Nav currentPage={navPages.BREAKDOWN} onLoggedOut={async () => await this.props.onLoggedOut()} />
        <Main>
          <div className="row">
            <div className="col mb-3">
              {
                this.state.breakdownPage ?
                  <TournamentsDropdown
                    tournaments={this.state.breakdownPage.tournaments}
                    tournament={this.state.breakdownPage.tournament}
                    getLinkDestination={t => getBreakdownUrl(t.currentRoundCode)} /> :
                  <Skeleton />
              }
            </div>
            <div className="col mb-3">
              {
                this.state.breakdownPage ?
                  <RoundsDropdown
                    rounds={this.state.breakdownPage.rounds}
                    round={this.state.breakdownPage.round}
                    includeOverall={false}
                    getRoundLinkDestination={code => getBreakdownUrl(code)} /> :
                  <Skeleton />
              }
            </div>
            <div className="col mb-3">
              {
                this.state.breakdownPage ?
                  (this.state.breakdownPage.tournament && this.state.breakdownPage.tournament.botsAllowed ?
                    <ShowPlayersOption onSetValue={v => this.onShowPlayersOptionChange(v)} /> :
                    null) :
                  <Skeleton />
              }
            </div>
          </div>

          {
            this.state.breakdownPage ?
              <BreakdownTable
                breakdownPage={this.state.breakdownPage}
                showPlayersOption={
                  this.state.breakdownPage.tournament && this.state.breakdownPage.tournament.botsAllowed ?
                    this.state.showPlayersOption :
                    showPlayersOptions.ONLY_HUMANS
                } /> :
              <Skeleton />
          }
        </Main>
      </>
    );
  }
}