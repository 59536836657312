import React from 'react';
import { signUp } from '../PtoAuth';
import Spinner from '../Components/Spinner';
import { Redirect, Link } from 'react-router-dom';
import Nav from '../Components/Nav';
import { getConfirmEmailAddressUrl, getForgotPasswordUrl, getLoginUrl } from '../Urls';
import Main from '../Components/Main';
import SupportLink from '../Components/SupportLink';
import { getPasswordErrorMessage } from '../Utils';
import Alert, { alertLevels } from '../Components/Alert';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.emailAddress,
      password: '',
      isRegistering: false,
      redirectToConfirmation: false,
      registerError: null,
      canRegister: false
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isRegistering: true });

    try {
      await signUp(this.state.username, this.state.password);
      this.setState({ isRegistering: false, redirectToConfirmation: true })
    }
    catch (err) {
      this.setState({
        isRegistering: false,
        registerError: getPasswordErrorMessage(err)
      });
    }
  };

  setCanRegister(username, password) {
    this.setState({ canRegister: username && password });
  }

  onUsernameChange = (e) => {
    const username = e.target.value;
    this.setState({ username: username });

    this.setCanRegister(username, this.state.password);
  }

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState({ password: password });

    this.setCanRegister(this.state.username, password);
  }

  render() {
    if (this.state.redirectToConfirmation) {
      return (<Redirect to={getConfirmEmailAddressUrl(this.state.username)} />);
    }

    return (
      <>
        <Nav onLoggedOut={async () => await this.props.onLoggedOut()} />
        <Main>
          <h2>Register</h2>

          <div className="row">
            <div className="col-md-8 col-lg-6 col-xl-4">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label>Email address</label>
                  <input type="text" className="form-control" name="username" defaultValue={this.state.username} onChange={(e) => this.onUsernameChange(e)} value={this.state.username} />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input type="password" className="form-control" name="password" onChange={(e) => this.onPasswordChange(e)} value={this.state.password} />
                </div>
                <div className="form-group">
                  <input type="submit" className="btn btn-primary mr-3" value="Register" disabled={!this.state.canRegister} />
                  <Spinner showSpinner={this.state.isRegistering} />
                </div>
                <div className="form-group text-danger">
                  {this.state.registerError}
                </div>
              </form>
            </div>
          </div>

          <h4>More</h4>
          <ul>
            <li>
              <Link to={getLoginUrl(this.state.username, false)}>I want to log in</Link>
            </li>
            <li>
              <Link to={getConfirmEmailAddressUrl(this.state.username)}>I've been sent a code to confirm my email address</Link>
            </li>
            <li>
              <Link to={getForgotPasswordUrl(this.state.username)}>I forgot my password!</Link>
            </li>
            <li>
              Having trouble? Email <SupportLink /> with any questions.
            </li>
          </ul>
        </Main>
      </>
    );
  }
}

export default RegisterPage;