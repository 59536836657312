import React from 'react';
import { Link } from 'react-router-dom';

export const DropdownDivider = (props) => {
  return (
    <div className="dropdown-divider"></div>
  );
};

export const DropdownItem = (props) => {
  let className = "dropdown-item";
  if (props.active) {
    className += " active";
  }

  return (
    <Link className={className} to={props.linkDestination}>{props.children}</Link>
  );
};

export const Dropdown = (props) => {
  return (
    <div className="dropdown">
      <button className="btn btn-outline-primary btn-block dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {props.title}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {props.children}
      </div>
    </div>
  );
}