import React from 'react';
import linq from 'linq';

import Player from '../Components/Player';
import { Dropdown, DropdownItem } from '../Components/Dropdown';

export default function PlayersDropdown(props) {
  return (
    <Dropdown title={<Player player={props.player} />}>
      {
        linq.from(props.players)
          .orderBy(p => p.isMe ? 0 : 1)
          .thenBy(p => p.isMyOtherPlayer ? 0 : 1)
          .thenBy(p => p.name)
          .toArray()
          .map(p =>
            <DropdownItem key={p.id} active={p.id === props.player.id} linkDestination={props.getLinkDestination(p.id)} >
              <Player player={p} />
            </DropdownItem>)
      }
    </Dropdown>
  );
};