import React from 'react';

export default function Main(props) {
  return (
    <main className="my-3">
      <div className="container">
        {props.children}
      </div>
    </main>
  );
};