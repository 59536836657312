import React from 'react';
import linq from 'linq';
import Skeleton from 'react-loading-skeleton';

import { updateFinalScore, postUpdateScores, getAdminFinalScoresPage } from '../PtoApi';
import RoundsDropdown from '../Components/RoundsDropdown';
import TournamentsDropdown from '../Components/TournamentsDropdown';
import MatchDataEntry from '../Components/MatchDataEntry';
import { groupMatchesByDate } from '../Utils';
import SavePlaceholder from '../SavePlaceholder';
import { formatDate } from '../Strings';
import Nav, { navPages } from '../Components/Nav';
import { getAdminFinalScoresUrl } from '../Urls';
import Main from '../Components/Main';

class UpdateScores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveStatus: null,
      error: null
    }
  }

  onUpdate = async () => {
    try {
      this.setState({
        saveStatus: 'SAVING',
        error: null
      });

      const response = await postUpdateScores(this.props.round.code);

      if (response.ok) {
        this.setState({
          saveStatus: 'SAVED',
          error: null
        });
      }
      else {
        const json = await response.json();
        this.setState({
          saveStatus: 'FAILED',
          error: json.errors[0].message
        });
      }
    }
    catch (err) {
      this.setState({
        saveStatus: 'FAILED',
        error: err.message
      });
    }

    await this.props.onUpdate();
  }

  render() {
    return (
      <div>
        <button className="btn btn-primary mr-3" onClick={async e => await this.onUpdate()}>Update scores</button>
        <SavePlaceholder status={this.state.saveStatus} error={this.state.error} />
      </div>
    );
  }
}

class MatchGroup extends React.Component {
  onSave = async (matchId, homeScore, awayScore) => {
    return await updateFinalScore(matchId, homeScore, awayScore);
  }

  render() {
    const items = linq.from(this.props.matches)
      .orderBy(m => m.kickoff)
      .thenBy(m => m.homeTeam.name)
      .select(m => <MatchDataEntry onSave={this.onSave} match={m} score={m.finalScore} key={m.id} isEditable={true} />)
      .toArray();

    return (
      <>
        <h4 className="mt-4 mb-3">{formatDate(this.props.date)}</h4>
        {items}
      </>
    );
  }
}

class AdminFinalScoresPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tournament: null,
      round: null,
      matches: null
    }
  }

  async load() {
    this.setState({
      tournaments: null,
      selectedTournament: null,
      rounds: null,
      selectedRound: null,
      matches: null
    });

    const response = await getAdminFinalScoresPage(this.props.roundCode);

    const json = await response.json();

    this.setState({
      isLoading: false,
      tournaments: json.tournaments,
      selectedTournament: json.selectedTournament,
      rounds: json.rounds,
      selectedRound: json.selectedRound,
      matches: json.matches
    });
  }

  async componentWillReceiveProps(newProps) {
    this.props = newProps;
    await this.load();
  }

  async componentDidMount() {
    await this.load();
  }

  render() {
    return (
      <>
        <Nav currentPage={navPages.ADMIN} onLoggedOut={async () => await this.props.onLoggedOut()} />
        <Main>
          <div className="row">
            <div className="col-6 mb-3">
              {
                this.state.tournaments && this.state.selectedTournament ?
                  <TournamentsDropdown
                    tournaments={this.state.tournaments}
                    tournament={this.state.selectedTournament}
                    getLinkDestination={t => getAdminFinalScoresUrl(t.currentRoundCode)} /> :
                  <Skeleton />
              }
            </div>
            <div className="col-6 mb-3">
              {
                this.state.rounds && this.state.selectedRound ?
                  <RoundsDropdown
                    rounds={this.state.rounds}
                    round={this.state.selectedRound}
                    includeOverall={false}
                    getRoundLinkDestination={roundCode => getAdminFinalScoresUrl(roundCode)} /> :
                  <Skeleton />
              }
            </div>
          </div>
          {
            this.state.selectedRound ?
              <UpdateScores round={this.state.selectedRound} onUpdate={async () => await this.load()} /> :
              <Skeleton />
          }
          {
            this.state.matches ?
              linq.from(groupMatchesByDate(this.state.matches))
                .select(g => <MatchGroup matches={g.matches} date={g.date} key={g.date} />)
                .toArray() :
              <Skeleton count={5} />
          }
        </Main>
      </>
    );
  }
}

export default AdminFinalScoresPage;