import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import TeamImage from '../Components/TeamImage';
import Name from '../Components/Name';
import TournamentsDropdown from '../Components/TournamentsDropdown';
import Nav from '../Components/Nav';

import { getForm } from '../PtoApi';
import { getTeamFormUrl } from '../Urls';
import Main from '../Components/Main';

function GetScore(match) {
  return `${match.teamScore} - ${match.opponentScore}`;
}

function GetOpponent(match) {
  return match.opponent.name;
}

function GetDetails(match) {
  return `${GetOpponent(match)} ${GetScore(match)}`;
}

function GetResultClassName(matchTeamResult) {
  switch (matchTeamResult) {
    case "WIN":
      return "result-win";
    case "DRAW":
      return "result-draw";
    case "LOSS":
      return "result-loss";
    default:
      return null;
  }
}

function GetResultBadgeContent(matchTeamResult) {
  switch (matchTeamResult) {
    case "WIN":
      return "W";
    case "DRAW":
      return "D";
    case "LOSS":
      return "L";
    default:
      return null;
  }
}

function TeamMatch(props) {
  const className = GetResultClassName(props.match.teamResult);

  const collapseId = `details-${props.team.id}-${props.match.matchId}`;

  return (
    <>
      <a data-toggle="collapse" href={`#${collapseId}`} role="button" aria-expanded="false" aria-controls="collapseExample">
        <span className={`badge badge-primary mr-1 ${className}`}>
          {GetResultBadgeContent(props.match.teamResult)}
        </span>
      </a>
      <span className="collapse mr-1" id={collapseId}>{GetDetails(props.match)}</span>
    </>
  )
}

function TeamRow(props) {
  const form = props.matches.map(m => <TeamMatch key={m.matchId} team={props.team} match={m} />);

  return (
    <tr>
      <td className="text-center">
        <TeamImage team={props.team} height="25" />
      </td>
      <td>
        <Name breakpoint="md" name={props.team.name} shortName={props.team.shortName} />
      </td>
      <td>{form}</td>
    </tr>
  )
}

function Form(props) {
  const teamRows = props.teams.map(t => <TeamRow key={t.team.id} team={t.team} matches={t.matches} />);

  return (
    <table className="table table-striped table-hover table-sm">
      <tbody>
        <tr>
          <th colSpan="2">Team</th>
          <th>Form</th>
        </tr>
        {teamRows}
      </tbody>
    </table>
  )
}

export default function FormPage(props) {
  const [teams, setTeams] = useState(null);
  const [tournament, setTournament] = useState(null);
  const [tournaments, setTournaments] = useState(null);

  useEffect(
    () => {
      const fetchData = async () => {
        // Clear previous values
        setTeams(null);
        setTournament(null);
        setTournaments(null);

        const formResponse = await getForm(props.tournamentCode);
        const formJson = await formResponse.json();

        setTeams(formJson.teams);
        setTournament(formJson.tournament);
        setTournaments(formJson.tournaments);
      };

      fetchData();
    },
    [props.tournamentCode]);

  return (
    <>
      <Nav onLoggedOut={async () => await props.onLoggedOut()} />
      <Main>
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-4 mb-3">
            {
              tournaments && tournament ?
                <TournamentsDropdown
                  tournaments={tournaments}
                  tournament={tournament}
                  getLinkDestination={t => getTeamFormUrl(t.code)} /> :
                <Skeleton />
            }
          </div>
        </div>

        <div>
          {
            teams ?
              <>
                <p>Click on a W/D/L to see more details.</p>
                <Form teams={teams} />
              </> :
              <Skeleton />
          }
        </div>
      </Main>
    </>
  );
};