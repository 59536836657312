import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Main from '../Components/Main';
import Nav from '../Components/Nav';
import TournamentHeader from '../Components/TournamentHeader';
import SupportLink from '../Components/SupportLink';
import { getCharityPage } from '../PtoApi';

export default function WorldCup2022CharityPage(props) {
  const [tournament, setTournament] = useState(null);

  useEffect(
    () => {
      const fetchData = async () => {
        // Clear previous values
        setTournament(null);

        const response = await getCharityPage(props.tournamentCode);
        const json = await response.json();

        setTournament(json.tournament);
      };

      fetchData();
    },
    []);

  return (
    <>
      <Nav onLoggedOut={props.onLoggedOut} />
      <Main>
        {
          tournament ?
            <TournamentHeader tournament={tournament} /> :
            <Skeleton />
        }
        <p>
          The predictions game for World Cup 2022 costs <strong>£5</strong> to enter.
          We expect that most people playing the game will have been invited by Dan or Andy Carter; if so, contact one of them to pay your entry fee.
          If not (hello!), please email <SupportLink /> and we'll let you know how you can pay.
        </p>

        <p>
          A small portion of the entry fees is set aside as a prize pot for the gold (£20), silver (£15) and bronze (£10) players.
          The rest is donated to <a href="https://www.refuge.org.uk/">Refuge</a>,
            a charity providing specialist support for women and children experiencing domestic violence.
        </p>

        <img src={`${process.env.PUBLIC_URL}/refuge-logo.jpg`} className="img-thumbnail mx-auto ml-sm-3 mr-sm-0 mb-3 d-block d-sm-inline float-sm-right" alt="Refuge logo" />

        <p>
          During the first UK lockdown due to COVID-19, the demand for Refuge's services went up by 65%, as victims of domestic abuse were forced to spend more time indoors.
          Refuge's work includes providing emergency temporary accommodation for women and children fleeing abuse,
            as well as the <a href="http://www.nationaldahelpline.org.uk/">National Domestic Abuse Hotline</a>.
        </p>

        <p>
          For more information, <a href="https://www.refuge.org.uk/">visit the Refuge website</a>.
        </p>
      </Main>
    </>
  );
};