import linq from 'linq';
import moment from 'moment';
import { getRoundLeaderboardUrl, getTournamentLeaderboardUrl, getRoundPredictionsUrl, getCreatePlayerUrl } from './Urls';

export function groupMatchesByDate(matches) {
  let matchesWithDate = linq.from(matches)
    .orderBy(m => m.kickoff)
    .thenBy(m => m.homeTeam.name)
    .select(m => { return { match: m, date: moment.utc(m.kickoff).local().format('YYYY-MM-DD') } });

  let matchesGroupedByDate = matchesWithDate
    .groupBy(m => m.date)
    .select(g => { return { date: g.key(), matches: g.getSource().map(m => m.match) } });

  return matchesGroupedByDate.toArray();
}

export function getPasswordErrorMessage(err) {
  const passwordLengthErrorMessage = "Your password must be at least 10 characters long";

  if (err.code === "InvalidParameterException" && err.message && err.message.includes("length")) {
    return passwordLengthErrorMessage;
  }

  if (err.code === "InvalidPasswordException" && err.message && err.message.includes("long")) {
    return passwordLengthErrorMessage;
  }

  return err.message;
};

export function getPredictionResultClass(predictionResult) {
  if (predictionResult === "EXACT_SCORE") {
    return "status-exact-score";
  }

  if (predictionResult === "CORRECT_RESULT") {
    return "status-correct-result";
  }

  if (predictionResult === "ONE_SCORE_CORRECT") {
    return "status-one-score-correct";
  }

  if (predictionResult === "WRONG") {
    return "status-wrong";
  }

  return "status-unknown";
}

export function getLeaderboardUrl(tournament, roundCode) {
  switch (tournament.defaultLeaderboardGranularity) {
    case "OVERALL":
      return getTournamentLeaderboardUrl(tournament.code);
    case "ROUND":
      return getRoundLeaderboardUrl(roundCode);
    default:
      return null;
  }
};

export function getPredictionsUrl(roundCode, player) {
  return player ? getRoundPredictionsUrl(roundCode, player.id) : getCreatePlayerUrl();
}