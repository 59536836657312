export function getTournamentLeaderboardUrl(tournamentCode) {
  return `/tournaments/${tournamentCode}/leaderboard`;
}

export function getRoundLeaderboardUrl(roundCode) {
  return `/rounds/${roundCode}/leaderboard`;
}

export function getRoundPredictionsUrl(roundCode, playerId) {
  return `/rounds/${roundCode}/predictions?playerId=${playerId}`;
}

export function getCreatePlayerUrl() {
  return `/createPlayer`;
};

export function getMatchUrl(matchId) {
  return `/matches/${matchId}`;
}

export function getBreakdownUrl(roundCode) {
  return `/rounds/${roundCode}/breakdown`;
}

export function getPredictedTableUrl(tournamentCode, playerId) {
  return `/tournaments/${tournamentCode}/players/${playerId}`;
}

export function getTeamFormUrl(tournamentCode) {
  return `/tournaments/${tournamentCode}/form`;
}

export function getAccountUrl() {
  return "/account";
}

export function getSubmitNewPasswordUrl(emailAddress) {
  let url = "/submitNewPassword";
  if (emailAddress) {
    url += "?emailAddress=" + emailAddress;
  }
  return url;
};

export function getAdminUrl() {
  return "/admin";
};

export function getAdminRoundsUrl(tournamentCode) {
  return `/admin/rounds?tournamentCode=${tournamentCode}`;
};

export function getAdminEditMatchesUrl(roundCode) {
  return `/admin/editMatches?roundCode=${roundCode}`;
};

export function getAdminFinalScoresUrl(roundCode) {
  return `/admin/finalScores?roundCode=${roundCode}`;
};

export function getAdminPlayersUrl() {
  return `/admin/players`;
};

export function getAboutUrl() {
  return "/about";
};

export function getHomeUrl() {
  return "/";
};

export function getLoginUrl(emailAddress, emailJustConfirmed) {
  let url = "/login";

  if (emailAddress || emailJustConfirmed) {
    url += "?";
  }

  if (emailAddress) {
    url += "&emailAddress=" + emailAddress;
  }

  if (emailJustConfirmed) {
    url += "&emailJustConfirmed=true";
  }

  return url;
};

export function getRegisterUrl(emailAddress) {
  let url = "/register";
  if (emailAddress) {
    url += "?emailAddress=" + emailAddress;
  }
  return url;
};

export function getConfirmEmailAddressUrl(emailAddress) {
  let url = "/confirmEmailAddress";
  if (emailAddress) {
    url += "?emailAddress=" + emailAddress;
  }
  return url;
};

export function getForgotPasswordUrl(emailAddress) {
  let url = "/forgotPassword";
  if (emailAddress) {
    url += "?emailAddress=" + emailAddress;
  }
  return url;
};

export function getWorldCup2022CharityUrl(){
  return "/tournaments/wc2022/charity";
};