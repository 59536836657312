import React, { useEffect, useState } from 'react';
import Points from '../Components/Points';
import { Link } from 'react-router-dom';
import Nav from '../Components/Nav';
import { getAccountUrl, getBreakdownUrl, getHomeUrl } from '../Urls';
import { getAboutPage } from '../PtoApi';
import { getLeaderboardUrl, getPredictionsUrl } from '../Utils';
import Main from '../Components/Main';
import SupportLink from '../Components/SupportLink';

const DelayedLink = (props) => {
  if (props.to) {
    return (
      <Link to={props.to}>{props.children}</Link>
    );
  } else {
    return (
      <>{props.children}</>
    );
  }
};

export default function AboutPage(props) {
  const [aboutPage, setAboutPage] = useState();

  useEffect(
    () => {
      const fetchData = async () => {
        // Clear previous values
        setAboutPage(null);

        const response = await getAboutPage();

        if (response) {
          const json = await response.json();

          setAboutPage(json);
        }
      };

      fetchData();
    },
    []);

  return (
    <>
      <Nav onLoggedOut={async () => await props.onLoggedOut()} />
      <Main>
        <p className="lead">
          <img className="mr-3" src={`${process.env.PUBLIC_URL}/pto-logo-navy.svg`} height="32px" alt="PTO logo" />
          Hi, I'm Paul The Octopus (PTO).
        </p>

        <p>
          You might know me for my <a href="https://en.wikipedia.org/wiki/Paul_the_Octopus#2010_FIFA_World_Cup">psychic abilities during the 2010 World Cup</a>.
        These days I spend my time hosting a website where you can emulate me.
        </p>

        <p>
          To play, all you have to do is predict the score.
          Sounds simple, but football is a notoriously slippery game.
        </p>

        <h3>How to play</h3>

        <p>
          The <Link to={getHomeUrl()}>Home page</Link> is the place to go to see an overview of the things you can do.
        </p>

        <p>
          First off, head to the <DelayedLink to={aboutPage ? getPredictionsUrl(aboutPage.round.code, aboutPage.player) : null}>Predictions page</DelayedLink>,
          where you can see the current round of matches and enter your predictions.
          If you want to see how you fared, head to the <DelayedLink to={aboutPage ? getLeaderboardUrl(aboutPage.tournament, aboutPage.round.code) : null}>Leaderboard page</DelayedLink>,
          or if you'd like more details of how the points were distributed then the <DelayedLink to={aboutPage ? getBreakdownUrl(aboutPage.round.code) : null}>Breakdown page</DelayedLink> is for you.
        </p>

        <h3>Rules</h3>

        <p>All predictions must be entered 15 minutes before kickoff. After this deadline, you can see everyone else's predictions.</p>

        <p>
          Any goals scored in extra time count towards the final score, but penalties in a shootout do not.
          For example, if the score after 90 minutes is 1-1 and after extra time it's 2-2, with one team winning 4-3 on penalties, then your prediction will be compared against the final score of 2-2.
        </p>

        <h4>Scoring</h4>
        <ul>
          <li><span className="status-exact-score"><Points points="5" /></span> if you get the score exactly right (e.g. you predict 2-0 and it finishes 2-0)</li>
          <li><span className="status-correct-result"><Points points="3" /></span> if you get the result right (e.g. you predict 2-0 and it finishes 2-1)</li>
          <li><span className="status-one-score-correct"><Points points="1" /></span> if you get one team's score right (e.g. you predict 2-0 and it finishes 0-0)</li>
          <li><span className="status-wrong"><Points points="0" /></span> if you get it wrong (e.g. you predict 2-0 and it finishes 1-2)</li>
        </ul>

        <h3>Help</h3>

        <p>
          If you have any questions about the site, or want help using it, please email <SupportLink />.
        </p>

        <p>
          If you would like to receive email reminders if you forget to enter predictions, there is a setting on <Link to={getAccountUrl()}>the account page</Link>.
        </p>

        <p>PTO uses icons from the following places:</p>

        <ul>
          <li>Main icon made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.</li>
          <li>Country icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></li>
        </ul>

        <p className="text-muted">Nerds might be interested in the fact that this is PTO build {parseInt(process.env.REACT_APP_BUILD_NUMBER, 10)} based on commit {process.env.REACT_APP_COMMIT_ID.substring(0, 7)}.</p>
      </Main>
    </>
  );
}