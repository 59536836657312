import React from 'react';
import Name from './Name';

const TournamentImage = (props) => {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/tournament-images/logo-${props.tournament.code}.svg`}
      alt={props.tournament.name}
      className="mr-3 align-middle" />
  );
};

export default function TournamentHeader(props) {
  return (
    <h2>
      <TournamentImage tournament={props.tournament} />
      <span className="align-middle">
        <Name breakpoint="sm" name={props.tournament.name} shortName={props.tournament.shortName} />
      </span>
    </h2>
  );
};