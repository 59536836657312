import React from 'react';
import { Link } from 'react-router-dom';

import { getWorldCup2022CharityUrl } from '../Urls';
import Alert, { alertLevels } from './Alert';

export default function WorldCup2022CharityAlert() {
  return (
    <Alert level={alertLevels.LIGHT}>
      This tournament has a £5 entry fee and raises money for <a className="alert-link" href="https://www.refuge.org.uk/">Refuge</a>.
      For more information, see the <Link className="alert-link" to={getWorldCup2022CharityUrl()}>charity page</Link>.
    </Alert>
  );
};